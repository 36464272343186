import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { useQuery } from "react-query";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SummarizeIcon from "@mui/icons-material/Summarize";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";

import {
  getWithdrawRequest,
  resendOpBatchId,
  withdrawRequestRecordResubmitBatch,
} from "../../api/merchant";
import { resubmitTransaction } from "../../api/resubmit";
import { CustomPagination } from "../../components/CustomPagination";
import { Button, Tooltip } from "../../components/MuiGenerals";
import { NoRowsOverlay } from "../../components/NoRowsOverlay";
import Prompter from "../../components/Prompter";
import { useAlerting, usePermission, useTranslation } from "../../hooks";
import {
  batchIdUrlString,
  dataGridDefaults,
  FeatureCodes,
} from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import ResubmitButton from "../Tools/ReSubmit/components/ResubmitButton";
import ResubmitPrompterContent from "../Tools/ReSubmit/components/ResubmitPrompterContent";
import { TableColumnKey } from "../Tools/ReSubmit/types/table";
import { useZusParams } from "./WithdrawRequestRecord";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";
import { checkIsResubmitBtnDisabled } from "../Tools/ReSubmit/helper/check-is-resubmit-btn-disabled";

const translatePrefix = "withdrawRequestRecord";
const Pkey = FeatureCodes.assetManagement.WithdrawRequestRecord;
export default function WithdrawRequestList(props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
}) {
  const { t, te } = useTranslation(translatePrefix);
  const { listMapping } = props;
  const { hasPermission } = usePermission();
  const zusParams = useZusParams();
  const listRes = useQuery({
    queryKey: ["getWithdrawRequest", zusParams.body, zusParams.refetchCounter],
    queryFn: () => getWithdrawRequest(zusParams.body),
  });
  const { rows = [], count = 0 } = listRes.data || {};
  const content: GridRowsProp = listMapping("key", rows, { t, te });

  const [isResubmitPrompterOpen, setIsResubmitPrompterOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>({});
  const translate = useTranslate();
  const { alerting } = useAlerting();
  const cellButton = (name: string) => (params: any) => {
    const batchId = params?.row?.batch_id;
    const [baseLink, icon] =
      name === "op"
        ? ["/operationRecords", <SummarizeIcon />]
        : ["/operationTransactionRecords", <AttachMoneyIcon />];
    const toNewTab = () =>
      window.open(`${baseLink}?${batchIdUrlString}${batchId}`);
    return (
      <Button onClick={toNewTab} color="secondary" variant="contained">
        {icon}
      </Button>
    );
  };

  const ResubmitButtonCell = ({ params }: { params: any }) => {
    const isBtnDisabled = checkIsResubmitBtnDisabled(
      params.row.rawData?.batch?.status
    );
    const handleClick = () => {
      setIsResubmitPrompterOpen(true);
      setSelectedRowData(params.row);
    };

    if (
      !hasPermission(
        FeatureCodes.assetManagement.WithdrawRequestRecord.Resubmit
      )
    ) {
      return <></>;
    }

    return (
      <ResubmitButton
        onClick={handleClick}
        isDisabled={isBtnDisabled}
        width="50%"
      />
    );
  };

  const handleResubmitYes = async () => {
    const payload = {
      batch_id: selectedRowData[TableColumnKey.BatchId],
    };

    const res = await withdrawRequestRecordResubmitBatch(payload);

    if (res) {
      alerting("success", translate("resubmit.alert.success"));
      listRes.refetch();
    }
  };

  const resendCellButtons = (params: GridRenderCellParams) => {
    return (
      <>
        <ResubmitButtonCell params={params} />
        <ActionButton {...{ params }} />
      </>
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol("0", t("operation"), {
      minWidth: 180,
      renderCell: resendCellButtons,
    }),
    useGenGridCol("batch_id", t("batch_id")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("hot_wallet_address", t("from_address")),
    useGenGridCol("status", t("status")),
    useGenGridCol("miner_fee_currency", t("miner_fee_currency")),
    useGenGridCol("miner_fee", t("miner_fee"), {
      minWidth: 150,
    }),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("total_amount", t("total_amount")),
    useGenGridCol("actual_amount", t("actual_amount")),
    useGenGridCol("total_count", t("total_count")),
    useGenGridCol("created_by", t("created_by"), {}),
    useGenGridCol("created_date", t("created_date")),
    useGenGridCol("last_modified_date", t("last_modified_date")),
    useGenGridCol("1", t("view_operation"), {
      renderCell: cellButton("op"),
    }),
    useGenGridCol("2", t("view_operation_transaction"), {
      renderCell: cellButton("opt"),
      minWidth: 170,
    }),
  ].filter((col) => {
    if (
      col.field === "0" &&
      !hasPermission(Pkey.Resend) &&
      !hasPermission(
        FeatureCodes.assetManagement.WithdrawRequestRecord.Resubmit
      )
    )
      return false;
    if (
      col.field === "1" &&
      !hasPermission(FeatureCodes.assetManagement.OperationRecords.prefix)
    )
      return false;
    if (
      col.field === "2" &&
      !hasPermission(
        FeatureCodes.assetManagement.OperationTransactionRecords.prefix
      )
    ) {
      return false;
    }
    return true;
  }) as GridColDef[];

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <>
      <Prompter
        title={translate("resubmit.prompter.title")}
        open={isResubmitPrompterOpen}
        onClose={() => setIsResubmitPrompterOpen(false)}
        content={
          <ResubmitPrompterContent
            batch_id={selectedRowData[TableColumnKey.BatchId]}
            operation_type={selectedRowData[TableColumnKey.OperationType]}
            chain_name={selectedRowData[TableColumnKey.ChainName]}
            asset_name={selectedRowData[TableColumnKey.AssetName]}
            total_amount={selectedRowData[TableColumnKey.TotalAmount]}
          />
        }
        onYes={handleResubmitYes}
      />
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={count}
        onPageChange={zusParams.setPage}
        page={zusParams.body.page}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const ActionButton = (props: any) => {
  const [hasSendArray, setHasSendArray] = useState<Array<number>>([]);
  const [isApiCallOnGoing, setIsApiCallOnGoing] = useState(false);
  const { alerting } = useAlerting();
  const { params } = props;
  const { t } = useTranslation(translatePrefix);
  let batch_id = params.row.batch_id;

  const handleResend = async () => {
    if (isApiCallOnGoing) {
      return;
    }
    setIsApiCallOnGoing(true);
    const res = await resendOpBatchId({ batch_id });
    if (res) {
      setHasSendArray((pre) => [...pre, batch_id]);
      alerting("success", t("resendSuccess"));
    }
    setIsApiCallOnGoing(false);
  };

  return (
    <>
      <Tooltip title={t("resend")}>
        <Button
          sx={{
            borderRadius: "18px",
            backgroundColor: "#4a90f7",
            height: "65%",
            width: "50%",
            marginLeft: "12px",
          }}
          variant="contained"
          onClick={handleResend}
          disabled={hasSendArray.includes(batch_id)}
        >
          {t("resend")}
        </Button>
      </Tooltip>
    </>
  );
};
